import { post, get } from "./request";
import { getEnv } from "__utils/constantsUtil";
import { APPCENTER_URL } from "../../config/auth0.config";

// Sets the constants for the pages for different users
export const calculated_Pages = ["INSTRUCTOR_SCHEDULE", "CERTIFICATIONS"];
export const admin_pages = [
  "CUSTOM_GROUP",
  "REPORT",
  "TRANSCRIPT",
  "CREATE_EXTERNAL_USER",
];
export const lms_pages = [
  "COURSE_MANAGEMENT",
  "CURRICULUM_MANAGEMENT",
  "EVENT_MANAGEMENT",
  "COURSE_ASSIGNMENT",
  "TEST_ENGINE",
];
export const defaultPages = ["LEARNING", "QS_DOCS", "COURSE_CATALOG"];

export function createDefaultFlags() {
  const defaultFlagsArray = [
    "Admin",
    "Sales",
    "ClinicalDev",
    "Supervisor",
    "US",
    "USCompanyUpdate",
    "RSM",
    "LakeForest",
    "Irvine",
    "Australia",
    "Europe",
    "Manager",
  ];
  const defaultFlags = {};

  for (let flag of defaultFlagsArray) {
    if (flag.includes("Manager")) {
      defaultFlags[`Is${flag}`] = false;
    } else {
      defaultFlags[`is${flag}`] = false;
    }
  }

  defaultFlags["proxyAccess"] = false;

  return defaultFlags;
}

export function setUserDetails(userInfo) {
  const {
    userName: username,
    city: City,
    costCenter: CostCenter,
    companyName: CompanyName,
    country: Country,
    firstName,
    lastName,
    email,
    location,
    subArea,
    department,
    permissions,
    featureFlags,
  } = userInfo;

  return {
    username: username.toUpperCase(),
    firstName,
    lastName,
    FullName: `${firstName} ${lastName}`,
    email: email.toUpperCase(),
    CostCenter,
    location,
    subArea,
    CompanyName,
    CountryCode: Country,
    City,
    featureFlags,
    department,
  };
}

export function setUserflags(outputUser, userInfo) {
  for (let flag of userInfo.flags) {
    if (flag.includes("Is")) {
      if (
        flag.includes("Manager") ||
        flag.includes("ExternalUserSupervisor") ||
        flag.includes("IsSupervisor")
      ) {
        outputUser["IsManager"] = true;
      } else {
        if (flag.includes("US")) {
          outputUser[`isUS`] = true;
          outputUser["isUSCompanyUpdate"] = true;
        } else {
          outputUser[`is${flag.slice(2)}`] = true;
        }
      }
    }
  }
  if (userInfo.groups.includes("PROXY_PAGE")) {
    console.log("proxyAccess group");
    outputUser["proxyAccess"] = true;
  }
  if (userInfo.groups.includes("TeamLead")) {
    outputUser["IsManager"] = true;
  }
}

export async function setPages(outputUser = {}) {
  const pageObject = {};

  // Gives access to the default pages
  if (outputUser.source === "TeamMembers") {
    for (let i = 0; i < defaultPages.length; i++) {
      outputUser.permissions.push(defaultPages[i]);
    }
  } else if (outputUser.source === "AgencyTemps") {
    outputUser.permissions.push(defaultPages[1]);
  } else {
    // external users
    outputUser.permissions.push(defaultPages[0]);
  }

  // Calculated roles
  if (outputUser.featureFlags.includes("LMS_EVENT_ADMIN")) {
    const INSTRUCTOR_URL = `${getEnv("BASEURL")}/Instructor/SearchEvents`;
    console.log(INSTRUCTOR_URL);
    await post({ url: INSTRUCTOR_URL, data: {} })
      .then((response) => {
        if (response.data.Data.length > 0) {
          pageObject[calculated_Pages[0]] = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  console.log("pageObject: ", pageObject);

  if (outputUser.featureFlags.includes("CERTIFICATIONS")) {
    pageObject[calculated_Pages[1]] = true;
  }

  outputUser.pages = Object.keys(pageObject);
  console.log(outputUser);
}

export async function checkExternalUserIfActive(outputUser = {}) {
  const { results } = await get({
    url: `${APPCENTER_URL}/ExternalUser/Search`,
    params: { keyword: outputUser.email.toLowerCase(), includeInactive: true },
  }).then((res) => res.data);

  console.log(
    "results: ",
    results.filter((elem) => elem.id === outputUser.ManualUserId)[0]
  );

  if (
    results.filter((elem) => elem.id === outputUser.ManualUserId)[0]
      ?.isActive === false
  ) {
    console.log("User is Inactive");
    outputUser.isActive = false;
  }
}
