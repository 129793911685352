import React from "react";
import "./Loading.scss";
import { useTranslation } from "react-i18next";

const LoadingComponent = ({ marginTop = true }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: "100%",
        marginTop: marginTop ? "30vh" : "0px",
      }}
    >
      <div styleName="loading-container">
        <div styleName="loader-wheel"></div>
        <div styleName="loading-text">{t("Loading...")}</div>
        <div styleName="please-wait-text">{t("Please wait.")}</div>
      </div>
    </div>
  );
};
export default LoadingComponent;
