import { useAuth0 } from "@auth0/auth0-react";
import loadable from "@loadable/component";
import { compareAsc } from "date-fns";
import i18n from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  DefaultToastContainer,
  ToastProvider,
} from "react-toast-notifications";
import { Toaster } from "sonner";
import { user } from "../../__shared/auth";
import { UserContext } from "../../context/user_context";
import { NotificationProvider } from "../../context/NotificationContext";
import AuthorizedRoute from "../AuthorizedRoute/AuthorizedRoute";
import Loading from "../Loading/Loading";
import "./App.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "Welcome to React": "Welcome to React and react-i18next",
        },
      },
    },
    lng: "en",
    keySeparator: false,
    nsSeparator: false,
    // saveMissing: true,
    // missingKeyHandler: function (lng, ns, key, fallbackValue) {
    //   return key;
    // },
    fallbackLng: "en",
    // debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

const MINUTE_MS = 60000;

const App = () => {
  const [loadError, setLoadError] = useState(undefined);
  const userContext = useContext(UserContext);
  const history = useHistory();
  const isTest =
    localStorage.getItem("playwrightTest") === "true" ? true : false;

  const { user: auth0user, isAuthenticated, logout } = useAuth0();

  function logoutExpiredSession() {
    if (localStorage.getItem("token_exp")) {
      const localLimit = new Date(localStorage.getItem("token_exp"));
      const currentTime = new Date();
      const checkLimit = compareAsc(localLimit, currentTime);

      console.log("time limit obj: ", {
        localLimit: localLimit,
        currentTime: currentTime,
        checkLimit,
      });
      if (checkLimit === -1) {
        localStorage.clear();
        logout({
          logoutParams: { returnTo: window.location.origin + "/Welcome" },
        });
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      logoutExpiredSession();
      const interval = setInterval(() => {
        logoutExpiredSession();
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
    return undefined;
  }, [isAuthenticated]);

  const CustomToastContainer = (props) => (
    <DefaultToastContainer
      {...props}
      style={{ zIndex: 10000, overflow: "hidden" }}
    />
  );

  // lazy loading as another bundle
  const LoadingContainer = loadable(() =>
    import(/* webpackPrefetch: true */ "../LoadingContainer/LoadingContainer")
  );
  const CatalogContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/catalog/components/catalog-container/CatalogContainer"
    )
  );
  const CoursePublishingContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/course-management/components/course-publishing-container/CoursePublishingContainer"
    )
  );
  const Curriculum = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/curriculum-management/components/curriculum-container/CurriculumContainer"
    )
  );
  const DocPageContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/controlled-documents/components/doc-page-container/DocPageContainer"
    )
  );
  const LearningContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/learning/components/learning-container/LearningContainer"
    )
  );
  const PDFPopup = loadable(() =>
    import(/* webpackPrefetch: true */ "../PDFPopup/PDFPopup")
  );
  const TeamContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/my-team/components/TeamContainer"
    )
  );
  const CourseAssignmentContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/course-assignment/components/course-assignment-container/CourseAssignmentContainer"
    )
  );
  const AdminCreation = loadable(() =>
    import(/* webpackPrefetch: true */ "@/features/admin-dashboard")
  );
  const EventContainer = loadable(() =>
    import(/* webpackPrefetch: true */ "../ILTEvents/EventContainer")
  );
  const AdminCreationContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/admin-dashboard/AdminCreationContainer/AdminCreationContainer"
    )
  );
  const TestEngineContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/test-engine/components/test-engine-container/TestEngineContainer"
    )
  );
  const TestTakingView = loadable(() =>
    import(/* webpackPrefetch: true */ "../TestTakingView/TestTakingView")
  );
  const TestReview = loadable(() =>
    import(/* webpackPrefetch: true */ "../TestReview/TestReview")
  );
  const ProxyContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/proxy/components/ProxyContainer"
    )
  );
  const WelcomePage = loadable(() =>
    import(/* webpackPrefetch: true */ "../WelcomePage/WelcomePage")
  );
  const CertificationManagementContainer = loadable(() =>
    import(
      /* webpackPrefetch: true */ "@/features/certification-management/components/CertificationManagementContainer/CertificationManagementContainer"
    )
  );

  useEffect(() => {
    if (!isAuthenticated && !window.location.pathname.includes("Welcome")) {
      sessionStorage.setItem("returnTo", window.location.pathname);
      history.replace("/Welcome");
    }
  }, []);

  if (
    !isTest &&
    !window.location.pathname.includes("Welcome") &&
    (userContext.settingUserData ||
      !auth0user?.["https://auth.appliedmedical.com/identity/userData"])
  ) {
    return (
      <div styleName="loading-container">
        <Loading />
        <div styleName={`button-container`}>
          <p>
            Issues logging in?{" "}
            <span style={{ display: "block" }}>Try logging out </span>
          </p>
          <button
            styleName="logout-btn"
            onClick={() =>
              logout({
                logoutParams: {
                  returnTo: window.location.origin + "/Welcome",
                },
              })
            }
          >
            Logout
          </button>
        </div>
      </div>
    );
  }

  return (
    <NotificationProvider>
      <ToastProvider
        components={{ ToastContainer: CustomToastContainer }}
        placement="top-right"
      >
        <Toaster />
        <Switch>
          <Route path="/Welcome" exact render={() => <WelcomePage />} />
          <AuthorizedRoute
            path="/"
            exact
            render={() => {
              if (user?.source === "AgencyTemps") {
                return (
                  <Redirect
                    to={{
                      pathname: "/Documents",
                    }}
                  />
                );
              }
              return (
                <Redirect
                  to={{
                    pathname: "/Learning",
                  }}
                />
              );
            }}
          />
          <AuthorizedRoute
            path="/Documents"
            exact
            render={(props) => <DocPageContainer {...props} />}
          />
          <AuthorizedRoute
            path={`/Learning/:type?/:id?/:isChild?/:childId?`}
            render={(props) => (
              <LearningContainer {...props} title="Training" />
            )}
          />
          <AuthorizedRoute
            path={`/Catalog/:type?/:id?/:isChild?/:childId?`}
            render={(props) => <CatalogContainer {...props} title="Catalog" />}
          />
          <AuthorizedRoute
            path={`/Coursepublishing/:userfilter/:formprocess`}
            exact
            render={(props) => <CoursePublishingContainer {...props} />}
          />
          <AuthorizedRoute
            path={`/Coursepublishing/:userfilter`}
            exact
            render={(props) => <CoursePublishingContainer {...props} />}
          />
          <Route
            path={`/Coursepublishing/search/:userinput`}
            exact
            render={(props) => <CoursePublishingContainer {...props} />}
          />
          <AuthorizedRoute
            path="/Coursepublishing"
            exact
            render={(props) => <CoursePublishingContainer {...props} />}
          />

          <AuthorizedRoute
            path="/CurriculumManagement/:userfilter/:formprocess"
            exact
            render={(props) => <Curriculum {...props} />}
          />
          <AuthorizedRoute
            path="/CurriculumManagement/:userfilter"
            exact
            render={(props) => <Curriculum {...props} />}
          />
          <AuthorizedRoute
            path="/CurriculumManagement"
            render={(props) => <Curriculum {...props} />}
          />
          <AuthorizedRoute
            path="/CertificationManagement"
            render={(props) => <CertificationManagementContainer {...props} />}
          />
          <AuthorizedRoute
            path="/Team"
            render={(props) => <TeamContainer {...props} />}
          />

          <AuthorizedRoute
            path="/Material/:url"
            exact
            render={(props) => <PDFPopup {...props} />}
          />

          <AuthorizedRoute
            path="/InstructorSchedule/:SessionId?"
            exact
            render={(props) => <EventContainer {...props} />}
          />

          <AuthorizedRoute
            path="/Courseassignment"
            exact
            render={(props) => <CourseAssignmentContainer {...props} />}
          />
          <AuthorizedRoute
            path="/Admin"
            exact
            render={(props) => <AdminCreation {...props} />}
          />
          <AuthorizedRoute
            path="/Admin/customgroup"
            exact
            render={(props) => <AdminCreationContainer {...props} />}
          />
          <AuthorizedRoute
            path="/Admin/createexternaluser"
            exact
            render={(props) => <AdminCreationContainer {...props} />}
          />
          <AuthorizedRoute
            path="/Admin/:page"
            exact
            render={(props) => <AdminCreationContainer {...props} />}
          />
          <AuthorizedRoute
            path="/Testengine"
            exact
            render={(props) => <TestEngineContainer {...props} />}
          />
          <AuthorizedRoute
            path="/TestTakingView/:examId/:assignmentId"
            exact
            render={(props) => <TestTakingView {...props} />}
          />

          <AuthorizedRoute
            path="/TestReview/:examId?/:assignmentId?"
            exact
            render={(props) => <TestReview {...props} />}
          />
          <Route
            path="/TestSession/:examId/:id"
            render={(props) => <LoadingContainer {...props} loadError={true} />}
          />
          <AuthorizedRoute
            path="/Proxy"
            exact
            render={(props) => <ProxyContainer {...props} />}
          />
          <Route
            path="/login"
            exact
            render={() => {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              );
            }}
          />

          <Route
            exact
            render={() => (
              <LoadingContainer
                loadError={loadError}
                setLoadError={setLoadError}
              />
            )}
          />
        </Switch>
      </ToastProvider>
    </NotificationProvider>
  );
};

export default App;
