import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useHistory } from "react-router-dom";
import { domain, clientId, audience, opts } from "../../../config/auth0.config";
export const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = async (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  console.log({ domain, audience, clientId });

  const redirectUri = window.location.origin;

  let connection = "";
  if (localStorage.getItem("playwrightTest")) {
    connection = "Test-Users";
  }

  // console.log(connection);

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain} // Learning Management System
      clientId={clientId} // Learning Management System
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: redirectUri,
        ...opts,
        connection: connection,
        scope: "offline_access",
      }}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
