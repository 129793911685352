import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading/Loading";
import { opts } from "../../../config/auth0.config";
import { updateGlobalJWT } from "__shared/auth";
import Truncate from "react-truncate";

const AuthWrapper = ({ children }) => {
  const {
    isLoading: auth0isLoading,
    isAuthenticated,
    error,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const lines = 2;
  const more = "Read More";
  const less = "Show Less";
  const buttonClassNames = "hover:underline cursor-pointer mt-3 text-sm";

  function toggleLines() {
    setExpanded((prev) => !prev);
  }

  function handleTruncate(newTruncatedState) {
    if (truncated !== newTruncatedState) {
      setTruncated(newTruncatedState);
    }
  }

  function parseJwt(token) {
    return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
  }

  useEffect(async () => {
    try {
      if (isAuthenticated) {
        const detailedToken = await getAccessTokenSilently({
          authorizationParams: opts,
          detailedResponse: true,
        });
        console.log(detailedToken);
        const { access_token, expires_in } = detailedToken;

        const decodedJwt = parseJwt(access_token);

        const { exp } = decodedJwt;

        console.log("detailedToken: ", detailedToken);

        if (!localStorage.getItem("token_exp")) {
          const timeLimit = new Date(exp * 1000);
          localStorage.setItem("token_exp", timeLimit.toISOString());
        }

        updateGlobalJWT(access_token);
      }
    } catch (e) {
      console.log("error: ", e.error);
      if (e.error === "missing_refresh_token" || e.error === "invalid_grant") {
        sessionStorage.setItem("returnTo", window.location.pathname);
        logout({
          logoutParams: { returnTo: window.location.origin + "/Welcome" },
        });
      }
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (auth0isLoading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className="flex h-screen w-full items-center bg-[#dedede]">
        <div className="m-auto max-h-[500px] w-[500px] overflow-auto rounded bg-white p-4 shadow-md">
          <p className="font-bold text-red-500">
            Error <span className="block text-black">{error.message}</span>
          </p>
          <Truncate
            lines={!expanded && lines}
            className="my-5 text-sm"
            ellipsis={
              <span>
                ...{" "}
                <button className={buttonClassNames} onClick={toggleLines}>
                  {more}
                </button>
              </span>
            }
            onTruncate={handleTruncate}
          >
            {error.stack}
          </Truncate>
          {!truncated && expanded && (
            <span>
              {" "}
              <button className={buttonClassNames} onClick={toggleLines}>
                {less}
              </button>
            </span>
          )}

          <p className="mt-2 text-xs italic">
            Contact corporate apps for further assistance
          </p>
        </div>
      </div>
    );
  }
  if (!auth0isLoading) {
    return <>{children}</>;
  }
};

export default AuthWrapper;
