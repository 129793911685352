import axios from "axios";

const instance = axios.create({
  baseURL: BASEURL,
});

/**
 * Options for showing a dialog.
 * @typedef {Object} baseConfig
 * @property {Object} [props.params] The parameters to send to the server
 * @property {Object} [props.headers] The headers of the request
 * @property {string} props.url The url of the request
 * @property {Object} [responseType] indicates the type of data that the server will respond with
 *
 *  options are: `arraybuffer`, `document`, `json`, `text`, `stream`, browser only: `blob`
 */

/**
 * @typedef {Object} DataProperties
 * @property {Object} [props.data] The body to send to the server
 * @typedef {baseConfig & DataProperties} requestWithData
 */

/**
 * Configuration for a GET request
 *
 * @param {baseConfig} props
 */
export const get = (props) =>
  instance({
    method: "get",
    params: {
      isPreview: ISPREVIEW,
      ...props.params,
    },
    ...props,
  });

/**
 * Configuration for a POST request
 *
 * @param {requestWithData} props
 */
export const post = (props) =>
  instance({
    method: "post",
    ...props,
  });

/**
 * Configuration for a PUT request
 *
 * @param {requestWithData} props
 */
export const put = (props) =>
  instance({
    method: "put",
    ...props,
  });

/**
 * Configuration for a PATCH request
 *
 * @param {requestWithData} props
 */
export const patch = (props) =>
  instance({
    method: "patch",
    ...props,
  });

/**
 * Configuration for a DEL request
 *
 * @param {requestWithData} props
 */
export const del = (props) =>
  instance({
    method: "delete",
    ...props,
  });

export default instance;
