import { user } from "__shared/auth";
import { get } from "__shared/request";
import { getEnv } from "__utils/constantsUtil";
import React, { createContext, useContext, useEffect, useState } from "react";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [pendingNotifications, setPendingNotifications] = useState({
    QS_DOCS: false,
    LEARNING: false,
  });

  const [isLoading, setIsLoading] = useState({
    QS_DOCS: false,
    LEARNING: false,
  });

  /**
   * Fetches notification data for a specific type
   * @param {('LEARNING'|'QS_DOCS')} type - The type of notification to fetch
   * @returns {Promise<void>}
   */
  const fetchData = async (type) => {
    if (isLoading[type]) return;

    setIsLoading((prev) => ({ ...prev, [type]: true }));

    try {
      const endpoints = {
        LEARNING: `${getEnv("BASEURL")}/Team/PendingAssignments/${
          user.source
        }/${user.userId}`,
        QS_DOCS: `${getEnv("BASEURL_QS")}/TeamMember/${
          user.userId
        }?filter=Pending`,
      };

      const { data } = await get({ url: endpoints[type] });

      const hasPending =
        type === "LEARNING"
          ? data.TotalPendingTrainings > 0
          : data.Assignments.length > 0;

      setPendingNotifications((prev) => ({ ...prev, [type]: hasPending }));
    } catch (error) {
      console.error(`Error fetching ${type} notifications:`, error);
    } finally {
      setIsLoading((prev) => ({ ...prev, [type]: false }));
    }
  };

  /**
   * Fetches pending notifications based on the specified page code
   * @param {string} [pageCode] - Optional page code to fetch specific notifications
   *                             If not provided, fetches all applicable notifications
   * @returns {Promise<void>}
   */
  const fetchPendingNotifications = async (pageCode) => {
    const canFetchDocs =
      (user.source === "AgencyTemps" || user.source === "TeamMembers") &&
      (!pageCode || pageCode === "QS_DOCS");

    if (!pageCode || pageCode === "LEARNING") {
      fetchData("LEARNING");
    }

    if (canFetchDocs) {
      fetchData("QS_DOCS");
    }
  };

  useEffect(() => {
    fetchPendingNotifications();
  }, []);

  return (
    <NotificationContext.Provider
      value={{ pendingNotifications, fetchPendingNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
