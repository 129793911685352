import React from "react";
import { render, hydrate } from "react-dom";
import App from "./components/App/App";
import "./style.css";
import "./styles/index.scss";
// Supports weights 300-800
import "@fontsource-variable/open-sans";
import { BrowserRouter } from "react-router-dom";
import setupLocatorUI from "@locator/runtime";
import { Auth0ProviderWithHistory } from "./components/Auth0ProviderWithHistory/Auth0ProviderWithHistory";
import { UserProvider } from "./context/user_context";
import AuthWrapper from "./components/AuthWrapper/AuthWrapper";

if (process.env.NODE_ENV === "development") {
  setupLocatorUI();
}

const rootElement = document.getElementById("root");
const currentWork = "Reroute Fix";
console.log("Current Work: ", currentWork);

if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <AuthWrapper>
          <UserProvider>
            <App />
          </UserProvider>
        </AuthWrapper>
      </Auth0ProviderWithHistory>
    </BrowserRouter>,
    rootElement
  );
} else {
  render(
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <AuthWrapper>
          <UserProvider>
            <App />
          </UserProvider>
        </AuthWrapper>
      </Auth0ProviderWithHistory>
    </BrowserRouter>,
    rootElement
  );
}
