import React from "react";
import { Route } from "react-router-dom";
import { user } from "__shared/auth";
import { useAuth0 } from "@auth0/auth0-react";

const AuthorizedRoute = ({ render: Component, ...rest }) => {
  const { isAuthenticated: isAuthenticatedAuth0, loginWithRedirect } =
    useAuth0();

  return (
    <>
      {isAuthenticatedAuth0 ? (
        <Route
          {...rest}
          render={(props) => <Component {...props} user={user} />}
        ></Route>
      ) : (
        loginWithRedirect()
      )}
    </>
  );
};

export default AuthorizedRoute;
