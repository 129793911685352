import "react";

export const APPCENTER_URL =
  "https://aks-cluster-prod.westus3.cloudapp.azure.com";
export const appCode = "LMS";
export const envCode = URLEnv();
// Auth0
export const domain = "dev-kwk94vpz.us.auth0.com";
export const clientId = "rwXFU9PjTYEtX7X5oiv8yGqEVdAV1A2U";
export const audience = "https://api.appliedmedical.com/LMS2";

export const opts = {
  audience,
  appCode,
  envCode
};

function URLEnv() {
  const isLocalhost = window.location.hostname === "localhost";
  const isProduction =
    window.location.hostname === "learning.appliedmedical.com";
  const QARegex = /appliedmedlms[0-9]?qa.z1.web.core.windows.net/;
  const POCRegex = /appliedmedlms[0-9]?poc.z1.web.core.windows.net/;
  const isLMSQA = QARegex.test(window.location.hostname);
  const isLMSPOC = POCRegex.test(window.location.hostname);

  if (isProduction) {
    return "PROD";
  } else if (isLMSQA) {
    return "QA";
  } else if (isLocalhost || isLMSPOC) {
    return "POC1";
  }
  return "POC1";
}
